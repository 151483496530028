<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <div class="d-flex align-center">
        <v-spacer></v-spacer>
        <v-btn class="shadow-off mb-2" text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
      </div>
      <v-row>
        <template v-if="listdata.length > 0">
          <v-col cols="12" sm="6" v-for="(v, k) in listdata" :key="k">
            <v-card link @click="$nova.gotoLink({path: '/earnout/project/view/'+v._id})">
              <v-card-text class="">
                <div class="d-flex align-center">
                  <v-chip small label v-if="v.stage===3" class="body-2" color="error">Closed</v-chip>
                  <v-chip small label text v-else class="body-2" color="success">Open</v-chip>
                  <v-spacer></v-spacer>
                  <v-menu offset-y transition="slide-y-transition" v-if="isreviewer">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="view-on-hover-item-d mx-0 px-0" icon v-bind="attrs" v-on="on"><v-icon>mdi-menu</v-icon></v-btn>
                    </template>
                    <v-list dense class="pa-0 border-left-default">
                      <v-list-item v-if="v.stage!==3" @click.stop="addeditproject={...v};editprojectdialog=true;addeditprojecterror={}">
                        <v-list-item-title><v-icon left>mdi-pencil</v-icon>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="v.stage===3" @click="openProject(k, v._id)">
                        <v-list-item-title><v-icon left>mdi-autorenew</v-icon>Re-Open</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else @click="closeProject(k, v._id)">
                        <v-list-item-title><v-icon left>mdi-cancel</v-icon>Close</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div class="py-2 d-flex align-center">
                  <div>
                    <div class="caption mb-n1">Target Company</div> 
                    <div class="subtitle-1 mt-0 font-weight-bold">{{v.targetcompany || ''}}</div> 
                  </div>
                  <div class="px-4">
                    <v-icon>mdi-arrow-right-bold</v-icon>
                  </div>
                  <div>
                    <div class="caption mb-n1">Buyer Company</div> 
                    <div class="subtitle-1 mt-0 font-weight-bold">{{v.buyercompany || ''}}</div> 
                  </div>
                </div>
                <div class="d-flex align-center grey--text text--darken-2">
                  <span class="pr-2 font-weight-bold">Aquisition Date:</span>
                  <span>{{$nova.formatDate(v.aquisitiondate)}}</span>
                  <v-spacer></v-spacer>
                </div>
                <div class="d-flex align-center mt-1">
                  <v-chip label small>{{v.network}}</v-chip>
                  <v-chip label small class="ml-2">{{v.region}}</v-chip>
                  <v-chip label small class="ml-2">{{v.country}}</v-chip>
                  <v-chip label small class="ml-2">{{v.currency}}</v-chip>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </template>

        <template v-else-if="loading">
          <v-col cols="12" sm="6" md="4">
            <v-skeleton-loader
              type="article, actions"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-skeleton-loader
              type="article, actions"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-skeleton-loader
              type="article, actions"
            ></v-skeleton-loader>
          </v-col>
        </template>

        <v-col cols="12" sm="6" md="4" v-else>
          <v-card>
            <v-card-text class="text-center my-5">
              <div class="grey--text title my-5">No Projects added yet</div>
              <div class="" v-if="isreviewer"><v-btn color="primary" @click="$nova.gotoLink({hash: '#add'})">Create Project</v-btn></div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-dialog v-model="addprojectdialog" max-width="600" persistent>
          <v-card :loading="addprojectloading" :disabled="addprojectloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Create Project</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="addprojectdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="12" class="mb-0 py-1">
                  <lb-string v-model="addeditproject.targetcompany" label="Target Company Name" :error="addeditprojecterror.targetcompany"/>
                </v-col>
                <v-col cols="12" class="mb-0 py-1">
                  <lb-string v-model="addeditproject.buyercompany" label="Buyer Company Name" :error="addeditprojecterror.buyercompany"/>
                </v-col>
                <v-col cols="4" class="my-0 py-1">
                  <lb-string v-model="addeditproject.currency" label="Currency" :error="addeditprojecterror.currency"/>
                </v-col>
                <v-col cols="4" class="my-0 py-1">
                  <lb-string v-model="addeditproject.country" label="Country" :error="addeditprojecterror.country"/>
                </v-col>
                <v-col cols="4" class="my-0 py-1">
                  <lb-string v-model="addeditproject.network" label="Network" :error="addeditprojecterror.network"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-string v-model="addeditproject.region" label="Region" :error="addeditprojecterror.region"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date v-model="addeditproject.aquisitiondate" label="Date of Aquisition" :error="addeditprojecterror.aquisitiondate"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown label="Associate User(s)" multiple v-model="addeditproject.userlist" :error="addeditprojecterror.userlist" :items="formdata.users" itemtext="name" itemvalue="_id" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="addProject()">Create</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="editprojectdialog" max-width="600" persistent>
          <v-card :loading="editprojectloading" :disabled="editprojectloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Edit Project</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="editprojectdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="12" class="mb-0 py-1">
                  <lb-string v-model="addeditproject.targetcompany" label="Target Company Name" :error="addeditprojecterror.targetcompany"/>
                </v-col>
                <v-col cols="12" class="mb-0 py-1">
                  <lb-string v-model="addeditproject.buyercompany" label="Buyer Company Name" :error="addeditprojecterror.buyercompany"/>
                </v-col>
                <v-col cols="4" class="my-0 py-1">
                  <lb-string v-model="addeditproject.currency" label="Currency" :error="addeditprojecterror.currency"/>
                </v-col>
                <v-col cols="4" class="my-0 py-1">
                  <lb-string v-model="addeditproject.country" label="Country" :error="addeditprojecterror.country"/>
                </v-col>
                <v-col cols="4" class="my-0 py-1">
                  <lb-string v-model="addeditproject.network" label="Network" :error="addeditprojecterror.network"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-string v-model="addeditproject.region" label="Region" :error="addeditprojecterror.region"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-date v-model="addeditproject.aquisitiondate" label="Date of Aquisition" :error="addeditprojecterror.aquisitiondate"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown label="Associate User(s)" multiple v-model="addeditproject.userlist" :error="addeditprojecterror.userlist" :items="formdata.users" itemtext="name" itemvalue="_id" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="editProject()">Update</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

      </v-row>
    </div>
  </div>
</template>

<script>
import {Chart, PolarAreaController,RadialLinearScale,ArcElement,Legend,Title,Tooltip,DoughnutController} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(PolarAreaController,RadialLinearScale,ArcElement,Legend,Title,Tooltip,DoughnutController,ChartDataLabels);

export default {
  name: 'earnout_project_list',
  data: function(){
    return {
      addeditproject: {},
      addeditprojecterror: {},
      addprojectdialog: false,
      isreviewer: false,
      formdata: {},
      addprojectloading: false,
      editprojectdialog: false,
      editprojectloading: false,
      loading: false,
      listdata: [],
      charts: [],
      chartdata: {},
      addeditstepper: 1,
      earnoutcount: 0,
    }
  },
  // components: {
  //   // pageHeader,managelist
  // },
  created() {
    if(this.$route.hash === "#add") {
      this.addeditproject = {};
      this.addeditprojecterror = {};
      this.addprojectdialog = true;
    }
    this.refreshData();
  },
  activated() {
    if(this.$route.hash === "#add") {
      this.addeditproject = {};
      this.addeditprojecterror = {};
      this.addprojectdialog = true;
    }
    this.refreshData();
  },
  mounted() {
  },
  methods: {
    refreshData() {
      this.isreviewer = (((this.$store.state.user || {}).permission || {}).earnout_assignment || []).indexOf('review') > -1
      this.charts = [];
      this.chartdata = {};
      this.listdata = [];
      this.getData();
    },
    getData() {
      this.loading = true;
      return this.axios.post("/v2/earnout/project/getformdata/").then(dt => {
        if(dt.data.status === "success"){
          this.formdata = dt.data.data[0] || {};
        }else throw new "error reading form data";
        return this.axios.post("/v2/earnout/project/get");
      }).then(dt => {
        this.listdata = [];
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.listdata = dt.data.data;
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    addProject() {
      this.addprojectloading = true;
      this.axios.post("/v2/earnout/project/add", {data: this.addeditproject}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "New Project Created");
          if(dt.data.data.length > 0){
            this.$nova.gotoLink({path: '/earnout/project/view/'+dt.data.data[0]._id})
          }else this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addeditprojecterror = dt.data.data[0].index0;
          }else throw new dt.data.message;
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addprojectloading = false;
      })
    },
    editProject() {
      this.editprojectloading = true;
      this.axios.post("/v2/earnout/project/edit/"+this.addeditproject._id, {data: this.addeditproject}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Updated");
          this.editprojectdialog = false;
          this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addeditprojecterror = dt.data.data[0].index0;
          }else throw new dt.data.message;
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.editprojectloading = false;
      })
    },
    closeProject(k, i) {
      this.loading = true;
      this.axios.post("/v2/earnout/project/close/"+i).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Closed");
          this.listdata[k].stage = 3;
          this.listdata.push({});
          this.listdata.pop();
          
        }else throw new "Error closing project";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    openProject(k, i) {
      this.loading = true;
      this.axios.post("/v2/earnout/project/open/"+i).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Re-opened");
          this.listdata[k].stage = 0;
          this.listdata.push({});
          this.listdata.pop();
        }else throw new "Error re-opening project";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
  },
  watch: {
    "addeditproject.count": function(d){
      console.log("count changed", d);
      this.earnoutcount = Number(d) || 0;
    },
    "$store.state.client": function(){
      this.refreshData();
    },
    "$route": function(d){
      if(d.hash === "#add") {
        this.addprojectdialog = true;
        this.addeditproject = {};
        this.addeditproject.reminderdays = this.addeditproject.reminderdays ?? 7;
      }
    },
    "addprojectdialog": function(d){
      if(!d) this.$nova.gotoLink({hash: ''});
    }
  }
}
</script>
